import React, { useContext, useEffect, useState } from 'react'
import { ChatContainer, MainContainer, Sidebar } from '@chatscope/chat-ui-kit-react'
import '../css/ChatCustom.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import ChatBoxContainer from './ChatBoxContainer'
import ChatBoxSideBar from './ChatBoxSideBar'
import { useDispatch, useSelector } from 'react-redux'
import { removeAuthorization, updateAuthorizationJwtToken } from '../redux/reducers/Auth.reducer'
import axios from 'axios'
import { PLATFORM_WEB, RESPONSE_STATUS_SUCCESS } from '../constants'
import { getToken } from 'firebase/messaging'
import { messaging } from '../firebase'
import { SocketContext } from '../contexts/socket'
import { updateMessageTemplates } from '../redux/reducers/Global.reducer'

let accessTokenTimeout
let pushTokenTimeout

function ChatPage () {
  const socket = useContext(SocketContext)
  const auth = useSelector(state => state.auth)
  const uiScope = useSelector(state => state.uiScope)
  const dispatch = useDispatch()

  function getAccessToken (access_jwt_token_expired) {
    let timeReUpdate = (access_jwt_token_expired - Math.round(Date.now() / 1000) - 300)
    timeReUpdate = timeReUpdate > 0 ? timeReUpdate : 0
    clearTimeout(accessTokenTimeout)
    if (auth.refresh_token) {
      accessTokenTimeout = setTimeout(async () => {
        const result = await axios.post('/auth/token/renew', { refresh_token: auth.refresh_token })
        const res = result.data
        if (res.status === RESPONSE_STATUS_SUCCESS) {
          localStorage.setItem('access_jwt_token', res.data.access_jwt_token)
          localStorage.setItem('access_jwt_token_expired', res.data.access_jwt_token_expired)
          dispatch(updateAuthorizationJwtToken({
            access_jwt_token: res.data.access_jwt_token,
            access_jwt_token_expired: res.data.access_jwt_token_expired
          }))
          socket.io.opts.extraHeaders = {
            'X-Token': auth.access_jwt_token,
          }
          socket.io.opts.auth = {
            'authorization': auth.access_jwt_token
          }
          socket.disconnect().connect()
          getAccessToken(res.data.access_jwt_token_expired)
        } else {
          localStorage.removeItem('access_jwt_token')
          localStorage.removeItem('access_jwt_token_expired')
          localStorage.removeItem('access_token')
          localStorage.removeItem('refresh_token')
          localStorage.removeItem('fcm_token')
          dispatch(removeAuthorization())
        }
      }, timeReUpdate * 1000)
    }
  }

  useEffect(() => {
    requestPermission()
    getAccessToken(auth.access_jwt_token_expired)
    socket.io.opts.extraHeaders = {
      'Authorization': auth.access_jwt_token,
      'X-Token': auth.access_jwt_token,
    }
    socket.io.opts.auth = {
      'authorization': auth.access_jwt_token
    }
    socket.disconnect().connect()

    setTimeout(async () => {
      const result = await axios.get('/employer/message/templates')
      dispatch(updateMessageTemplates(result?.data?.data || []))
    })
  }, [])

  const [isNotification, setIsNotification] = useState({})

  function requestPermission () {
    if (typeof Notification !== 'undefined') {
      Notification.requestPermission().then(async (permission) => {
        if (permission === 'granted') {
          const swReg = await navigator.serviceWorker.register(`${process.env.REACT_APP_ENVIRONMENT === 'local' ? '/' : '/chat/'}firebase-messaging-sw.js`)
          getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_MESSAGE_VAPID_KEY,
            serviceWorkerRegistration: swReg
          }).then(async (currentToken) => {
            if (currentToken) {
              clearTimeout(pushTokenTimeout)
              pushTokenTimeout = setTimeout(async () => {
                const result = await axios.post('/me/device/token/add', {
                  device_token: currentToken,
                  platform: PLATFORM_WEB
                })
                if (result.data.status === RESPONSE_STATUS_SUCCESS) {
                  localStorage.setItem('fcm_token', currentToken)
                }
              }, 500)
            } else {
              console.log('No registration token available. Request permission to generate one.')
            }
          }).catch(err => {
            console.log('An error occurred while retrieving token. ', err)
          })
          setIsNotification(false)
        } else {
          setIsNotification(permission !== 'denied')
        }
      }).catch(err => console.log(err))
    } else {
      setIsNotification(false)
    }
  }

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <MainContainer responsive>
        <ChatBoxSideBar/>
        <ChatBoxContainer as={ChatContainer}/>
        {uiScope.isOpenRightSidebar ? <Sidebar position="right"/> : null}

      </MainContainer>
      {
        isNotification ? (
          <div style={{
            position: 'fixed',
            zIndex: 1000,
            bottom: 50,
            left: 10,
            width: 50,
            height: 50,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden'
          }}
               onClick={requestPermission}
          >
            <FontAwesomeIcon icon={faBell} style={{ fontSize: 30, color: 'orange' }}/>
          </div>
        ) : null
      }
    </div>
  )
}

export default ChatPage
