import { createSlice } from '@reduxjs/toolkit'

export const defaultSlice = createSlice({
  name: 'uiScope',
  initialState: {
    isOpenRightSidebar: false
  },
  reducers: {
    openRightSidebar: (state, action) => {
      state.isOpenRightSidebar = true
    },
    closeRightSidebar: (state, action) => {
      state.isOpenRightSidebar = false
    },
    toggleRightSidebar: (state, action) => {
      state.isOpenRightSidebar = !state.isOpenRightSidebar
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  openRightSidebar,
  closeRightSidebar,
  toggleRightSidebar
} = defaultSlice.actions

export default defaultSlice.reducer
