import React from 'react'
import io from 'socket.io-client'

const socketClient = io.connect(process.env.REACT_APP_ENDPOINT_WEBSOCKET, {
    reconnectionDelayMax: 10000,
    path: '/inbox',
    withCredentials: false,
    autoConnect: false
  }
)
socketClient.on('connect', function () {})
socketClient.on('disconnect', function (reason) {})
socketClient.on('connect_error', (err) => {})
export const socket = socketClient
export const SocketContext = React.createContext(socketClient)
