import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Rating } from '@mui/material'
import __ from '../locales/locale'
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { RESPONSE_STATUS_ERROR, RESPONSE_STATUS_SUCCESS } from '../constants.js'
import MessageBoxDialog from './MessageBoxDialog.js'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { FirebaseContext } from '../contexts/firebase.js'

function UserEvaluationDialog (props) {
  const firebase = useContext(FirebaseContext)
  const auth = useSelector((state) => state.auth)
  const currentConversation = useSelector(state => state.chatbox.currentConversation)
  const [valueEvaluation, setValueEvaluation] = useState(0)
  const [evaluationLastTimeAt, setEvaluationLastTimeAt] = useState('')
  const [openMessageBox, setOpenMessageBox] = useState(false)
  const [titleMessageBox, setTitleMessageBox] = useState('')
  const [contentMessageBox, setContentMessageBox] = useState('')

  useEffect(() => {
    if (props.open) {
      getEvaluation()
      formEvent('form_start')
    }
  }, [props.open])

  const getEvaluation = async () => {
    setValueEvaluation(0)
    setEvaluationLastTimeAt('')
    const result = await axios.get('/me/evaluation',
      {
        params: {
          receiver_id: currentConversation.receiver_id
        }
      }
    )
    if (result.data.status === RESPONSE_STATUS_SUCCESS) {
      if (result?.data?.data) {
        setValueEvaluation(result.data.data.point)
        setEvaluationLastTimeAt(moment(result.data.data.updated_at).calendar())
      }
    }
  }

  const onUpdateEvaluation = async () => {
    const postFields = {}
    postFields['point'] = valueEvaluation
    postFields['receiver_id'] = currentConversation.receiver_id

    const result = await axios.post('/me/evaluation/update', postFields)
    if (result.data.status === RESPONSE_STATUS_SUCCESS) {
      props.onClose()
      setOpenMessageBox(true)
      setTitleMessageBox(__('alert'))
      setContentMessageBox(__('evaluate_success_message'))
    }

    if (result.data.status === RESPONSE_STATUS_ERROR) {
      props.onClose()
      setOpenMessageBox(true)
      setTitleMessageBox(__('error'))
      setContentMessageBox(__('evaluate_failure_message'))
    }
    formEvent('form_submit', { form_submit_text: __('submit') })
  }

  function formEvent (type, data) {
    data = {
      form_id: `${auth.user_id}_evaluate_${currentConversation.receiver_id}`,
      form_name: `Evaluate ${currentConversation.receiver.full_name}`,
      ...data
    }
    if (type === 'form_start') {
      firebase.analyticsFormStartEvent(data)
    }
    if (type === 'form_submit') {
      firebase.analyticsFormSubmitEvent(data)
    }
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>{__('evaluation')}</DialogTitle>
        <DialogContent sx={{ maxWidth: '100%', width: 400, boxSizing: 'border-box' }}>
          <div style={{ width: 'auto', justifyContent: 'center', display: 'flex', marginBottom: 20 }}>
            <Rating
              style={{ alignItems: 'center' }}
              value={valueEvaluation}
              size={'large'}
              onChange={(event, newValue) => {
                setValueEvaluation(newValue)
              }}
            />
          </div>
          {evaluationLastTimeAt ?
            <FormHelperText>{__('modified_at') + `: ${evaluationLastTimeAt}`}</FormHelperText> : ''}
        </DialogContent>
        <DialogActions>
          <Button onClick={onUpdateEvaluation} variant={'contained'}>{__('evaluate')}</Button>
          <Button onClick={() => {
            props.onClose()
            formEvent('form_submit', { form_submit_text: __('cancel') })
          }}
          >{__('cancel')}</Button>
        </DialogActions>
      </Dialog>
      <MessageBoxDialog
        open={openMessageBox}
        title={titleMessageBox}
        content={contentMessageBox}
        onClose={() => setOpenMessageBox(false)}
      />
    </div>
  )
}

export default UserEvaluationDialog
