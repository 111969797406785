import { Button, Divider, Stack } from '@mui/material'
import React, { useState } from 'react'
import axios from 'axios'
import {
  INFO_PERMISSION_APPROVED,
  INFO_PERMISSION_PENDING,
  INFO_PERMISSION_REJECTED,
  RESPONSE_STATUS_SUCCESS,
  USER_TYPE_HCW,
  USER_TYPE_MED
} from '../../constants.js'
import __ from '../../locales/locale.js'
import { useSelector } from 'react-redux'
import OverlayLoading from '../../common_components/OverlayLoading'
import { useConfirm } from 'material-ui-confirm'

function MessageRequestPermissionItem (props) {
  const auth = useSelector((state) => state.auth)
  const currentConversation = useSelector((state) => state.chatbox.currentConversation)
  const confirm = useConfirm()
  const { message } = props
  const [isOverlayLoading, setOverlayLoading] = useState(false)

  let employerId = 0
  if (currentConversation?.receiver?.type === USER_TYPE_MED) {
    employerId = currentConversation.receiver.external_id
  }

  const openApproveDialog = async () => {
    await confirm({
      title: '',
      description: __('confirm_message_approve_information_permission'),
      confirmationText: __('approve'),
    })
      .then(handleApprove)
      .catch(null)
  }

  const openRejectDialog = async () => {
    await confirm({
      title: '',
      description: __('confirm_message_reject_information_permission'),
      confirmationText: __('reject'),
    })
      .then(handleReject)
      .catch(null)
  }

  const handleApprove = async () => {
    setOverlayLoading(true)
    try {
      const result = await axios.post('/employee/information/permission/accept', { employer_id: employerId })
      const res = result.data
      if (res.status === RESPONSE_STATUS_SUCCESS) {
        await confirm({
          title: '',
          description: res?.message,
          hideCancelButton: true,
          confirmationText: __('ok')
        })
      } else {
        await confirm({
          title: '',
          description: res?.message,
          hideCancelButton: true,
          confirmationText: __('ok')
        })
      }
    } catch (e) {

    }
    setOverlayLoading(false)
  }

  const handleReject = async () => {
    setOverlayLoading(true)
    try {
      const result = await axios.post('/employee/information/permission/reject', { employer_id: employerId })
      const res = result.data
      if (res.status === RESPONSE_STATUS_SUCCESS) {
        await confirm({
          title: '',
          description: res?.message,
          hideCancelButton: true,
          confirmationText: __('ok')
        })
      } else {
        await confirm({
          title: '',
          description: res?.message,
          hideCancelButton: true,
          confirmationText: __('ok')
        })
      }
    } catch (e) {

    }
    setOverlayLoading(false)
  }

  const messageBuilder = () => {
    let buttons = (
      <Stack direction={'row'}
             spacing={2}
             divider={<Divider orientation="vertical" flexItem/>}
      >
        <Button onClick={openApproveDialog}>{__('approve')}</Button>
        <Button onClick={openRejectDialog}>{__('reject')}</Button>
      </Stack>
    )
    if (message?.info_permission_status === INFO_PERMISSION_PENDING) {
      return (
        <Stack spacing={2} alignItems={'center'}>
          <span>{message.message}</span>
          {auth.type === USER_TYPE_HCW && auth.user_id !== message.sender_id ? buttons : ''}
        </Stack>
      )
    }
    if (message?.info_permission_status === INFO_PERMISSION_APPROVED) {
      buttons = <span>{__('approved')}</span>
    }
    if (message?.info_permission_status === INFO_PERMISSION_REJECTED) {
      buttons = <span>{__('rejected')}</span>
    }
    return (
      <Stack spacing={2} alignItems={'center'}>
        <span>{message.message}</span>
        {buttons}
      </Stack>
    )
  }
  return (
    <span>
      {messageBuilder()}
      <OverlayLoading open={isOverlayLoading}/>
    </span>
  )
}

export default MessageRequestPermissionItem
