export const MESSAGE_TYPE_SYSTEM = 0;
export const MESSAGE_TYPE_TEXT = 1;
export const MESSAGE_TYPE_IMAGE = 2;
export const MESSAGE_TYPE_VIDEO = 3;
export const MESSAGE_TYPE_PDF_RESUME = 4
export const MESSAGE_TYPE_PDF_CAREER = 5
export const MESSAGE_TYPE_SCOUT = 6
export const MESSAGE_TYPE_INFO_PERMISSION = 7
export const MESSAGE_TYPE_PAY_INFO = 8
export const MESSAGE_INCOMING = 'incoming';
export const MESSAGE_OUTGOING = 'outgoing';
export const MESSAGE_IS_SENT = 0;
export const MESSAGE_IS_SENDING = 1;
export const MESSAGE_IS_ERROR = 2;

export const CONVERSATION_TYPE_PRIVATE = 1;
export const CONVERSATION_TYPE_GROUP = 2;

export const USER_TYPE_HCW = 1;
export const USER_TYPE_MED = 2;
export const USER_TYPE_TO_NAME = ['guest', 'hcw', 'med'];
export const USER_TYPE_TO_CODE = { 'hcw': USER_TYPE_HCW, 'med': USER_TYPE_MED };

export const USER_ONLINE = 1;
export const USER_OFFLINE = 0;
export const USER_BANNED = 1;
export const USER_UNBANNED = 0;

export const USER_GENDER_UNKNOWN = 0;
export const USER_GENDER_MALE = 1;
export const USER_GENDER_FEMALE = 2;
export const USER_GENDER_TO_NAME = ['unknown', 'male', 'female'];

export const RESPONSE_STATUS_SUCCESS = 'success';
export const RESPONSE_STATUS_ERROR = 'error';

export const PDF_CV_STATUS_EXPIRED = 'expired'
export const PDF_CV_STATUS_UPDATED = 'disabled'
export const PDF_CV_STATUS_LIVING = 'living'


export const USER_STATUS_PENDING_ACTIVE_EMAIL = 1
export const USER_STATUS_ADMIN_APPROVAL = 2
export const USER_STATUS_ACTIVE = 3
export const USER_STATUS_INACTIVE = 4
export const USER_STATUS_BANNED = 5
export const USER_STATUS_PENDING_DELETE = 6
export const USER_STATUS_DELETED = 7

export const JOB_STATUS_UPDATED = 'updated'
export const JOB_STATUS_NORMAL = 'normal'
export const JOB_STATUS_INVALID = 'invalid'

export const PLATFORM_WEB = 0
export const PLATFORM_ANDROID = 1
export const PLATFORM_IOS = 2
export const PLATFORM_TO_NAME = ['web', 'android', 'ios']

export const INFO_PERMISSION_PENDING = 1
export const INFO_PERMISSION_APPROVED = 2
export const INFO_PERMISSION_REJECTED = 3
