import React from 'react'
import '../css/LoginForm.css'
import axios from 'axios'
import { setAuthorization } from '../redux/reducers/Auth.reducer'
import { useDispatch } from 'react-redux'
import { RESPONSE_STATUS_ERROR, RESPONSE_STATUS_SUCCESS } from '../constants'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FilledInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { LockRounded, MailRounded, MergeTypeRounded } from '@mui/icons-material'
import { Controller, useForm } from 'react-hook-form'
import { __ } from '../locales/locale'

export default function LoginForm () {
  const authorization = {
    access_jwt_token: localStorage.getItem('access_jwt_token'),
    access_jwt_token_expired: localStorage.getItem('access_jwt_token_expired'),
    access_token: localStorage.getItem('access_token'),
    refresh_token: localStorage.getItem('refresh_token'),
    user_id: localStorage.getItem('user_id'),
  }
  const [open, setOpen] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const dispatch = useDispatch()

  if (!!authorization.access_jwt_token && !!authorization.refresh_token) {
    dispatch(setAuthorization(authorization))
  }
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      email: '',
      password: '',
      type: 'hcw',
    },
  })

  const onSubmit = async data => {
    const response = await axios.post('/auth/login', {
        email: data.email,
        password: data.password,
        type: data.type,
      }
    )
    const result = response.data

    if (result.status === RESPONSE_STATUS_SUCCESS) {
      localStorage.setItem('access_jwt_token', result.data?.access_jwt_token)
      localStorage.setItem('access_jwt_token_expired', result.data?.access_jwt_token_expired)
      localStorage.setItem('access_token', result.data?.access_token)
      localStorage.setItem('refresh_token', result.data?.refresh_token)
      localStorage.setItem('user_id', result.data?.id)
      localStorage.setItem('external_id', result.data?.external_id)
      localStorage.setItem('type', result.data?.type)

      window.location.reload()
    }

    if (result.status === RESPONSE_STATUS_ERROR) {
      setErrorMessage(result.message)
      setOpen(true)
    }
  }

  if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
    window.location.href = '/login'
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ maxWidth: 500, width: '100%' }}>
          <div style={{ textAlign: 'center' }}>
            <CircularProgress/>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ maxWidth: 500, width: '90%' }}>
        <div style={{ marginBottom: 20, textAlign: 'center' }}>
          <Typography variant="h4">{__('login_description')}</Typography>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            rules={{ required: __('required_field') }}
            render={({ field }) => {
              return <FormControl
                error={!!errors?.email?.message}
                color={errors?.email?.message ? 'error' : ''}
                fullWidth sx={{ m: '5px 0' }}
                variant="filled"
              >
                <InputLabel htmlFor="filled-adornment-amount">{__('your_email')}</InputLabel>
                <FilledInput
                  {...field}
                  placeholder={__('please_input_your_email')}
                  startAdornment={
                    <InputAdornment position="start">
                      <MailRounded/>
                    </InputAdornment>
                  }
                />
                <FormHelperText>{errors.email?.message}</FormHelperText>
              </FormControl>
            }}
          />
          <Controller
            name="password"
            control={control}
            rules={{ required: __('required_field') }}
            render={({ field }) => {
              return <FormControl
                fullWidth sx={{ m: '5px 0' }} variant="filled"
                error={!!errors?.password?.message}
                color={errors?.password?.message ? 'error' : ''}
              >
                <InputLabel htmlFor="filled-adornment-amount">{__('your_password')}</InputLabel>
                <FilledInput
                  {...field}
                  type={'password'}
                  placeholder={__('please_input_your_password')}
                  startAdornment={
                    <InputAdornment position="start">
                      <LockRounded/>
                    </InputAdornment>
                  }
                />
                <FormHelperText>{errors.email?.message}</FormHelperText>
              </FormControl>
            }}
          />
          <Controller
            name="type"
            control={control}
            rules={{ required: __('required_field') }}
            render={({ field }) => {
              return <FormControl
                fullWidth sx={{ m: '5px 0' }} variant="filled"
                error={!!errors?.type?.message}
                color={errors?.type?.message ? 'error' : ''}
              >
                <InputLabel htmlFor="filled-adornment-amount">{__('type')}</InputLabel>
                <Select
                  {...field}
                  startAdornment={
                    <InputAdornment position="start">
                      <MergeTypeRounded/>
                    </InputAdornment>
                  }
                >
                  <MenuItem value={'hcw'}>{__('hcw')}</MenuItem>
                  <MenuItem value={'med'}>{__('med')}</MenuItem>
                </Select>
                <FormHelperText>{errors.type?.message}</FormHelperText>
              </FormControl>
            }}
          />

          <Stack spacing={2} direction="row" style={{ justifyContent: 'center', marginTop: 20 }}>
            <Button variant="contained" type={'submit'} style={{ padding: '10px 50px' }}>{__('login')}</Button>
          </Stack>
        </form>
      </div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{__('alert')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{__('ok')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
