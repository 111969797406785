import React from 'react'

import * as firebaseAdapter from './../firebase.js'
import { analytics } from './../firebase.js'
import { logEvent } from 'firebase/analytics'

export const firebase = {
  app: firebaseAdapter,
  analytics,
  analyticsPageViewEvent: (data) => logEvent(analytics, 'page_view', data),
  analyticsSearchEvent: (data) => logEvent(analytics, 'search', data),
  analyticsLogoutEvent: (data) => logEvent(analytics, 'logout', data),
  analyticsClickEvent: (data) => logEvent(analytics, 'click', data),
  analyticsFormStartEvent: (data) => logEvent(analytics, 'form_start', data),
  analyticsFormSubmitEvent: (data) => logEvent(analytics, 'form_submit', data),
}
export const FirebaseContext = React.createContext(firebase)
