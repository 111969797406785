import React from 'react'
import '../css/LoginForm.css'
import axios from 'axios'
import { useSelector } from 'react-redux'
import ChatPage from '../components/ChatPage'
import LoginForm from '../components/LoginForm'

export default function ChatBoxPage () {
  const auth = useSelector(state => state.auth);
  axios.defaults.headers.common['Authorization'] = auth.access_jwt_token;
  const chatForm = <ChatPage />;
  const loginForm = <LoginForm />;
  if (!!auth.access_jwt_token && !!auth.refresh_token) return chatForm;
  return loginForm;
}
