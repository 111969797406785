import { createSlice } from '@reduxjs/toolkit'

export const defaultSlice = createSlice({
  name: 'message_template',
  initialState: {
    messageTemplateId: '',
    messageTemplateContent: ''
  },
  reducers: {
    changeSelectMessageTemplate: (state, action) => {
      state.messageTemplateId = action.payload?.id
      state.messageTemplateContent = action.payload?.content
    },
    changeContentMessageTemplate: (state, action) => {
      state.messageTemplateContent = action.payload?.content
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  changeSelectMessageTemplate,
  changeContentMessageTemplate
} = defaultSlice.actions

export default defaultSlice.reducer
