import * as React from 'react'
import Menu from '@mui/material/Menu'
import { IconButton, ListItemIcon } from '@mui/material'
import * as MaterialIcon from '@mui/icons-material'
import MenuItem from '@mui/material/MenuItem'

export default function BasicMenu (props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getMenus = () => {
    const items = props.items || []
    return items.map((item) => {
      return (
        <MenuItem onClick={() => {
          item.action()
          handleClose()
        }}>
          <ListItemIcon>
            {item.icon}
          </ListItemIcon>
          {item.title}
        </MenuItem>
      )
    })
  }

  return (
    <div style={{ display: (props.items ?? []).length === 0 ? 'none' : 'block' }}>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MaterialIcon.MoreHoriz/>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {getMenus()}
      </Menu>
    </div>
  )
}
