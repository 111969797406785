import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2
import __ from '../locales/locale'
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { RESPONSE_STATUS_ERROR, RESPONSE_STATUS_SUCCESS, USER_TYPE_HCW, USER_TYPE_MED } from '../constants.js'
import { useSelector } from 'react-redux'
import { FirebaseContext } from '../contexts/firebase.js'
import OverlayLoading from '../common_components/OverlayLoading'
import { useConfirm } from 'material-ui-confirm'

function UserPaymentDialog (props) {
  const firebase = useContext(FirebaseContext)
  const auth = useSelector((state) => state.auth)
  const currentConversation = useSelector(state => state.chatbox.currentConversation)
  const [isOverlayLoading, setOverlayLoading] = useState(false)
  const confirm = useConfirm()

  useEffect(() => {
    if (props.open) {
      formEvent('form_start')
    }
  }, [props.open])

  const handlePayment = async () => {
    props.onClose()
    setOverlayLoading(true)
    try {
      const postFields = props.data
      if (currentConversation.receiver.type === USER_TYPE_HCW && currentConversation.sender.type === USER_TYPE_MED) {
        postFields['employee_id'] = currentConversation.receiver.external_id
      }
      const result = await axios.post('/payment/unmask/point/pay', postFields)
      setOverlayLoading(false)
      const res = result.data
      if (result.data.status === RESPONSE_STATUS_SUCCESS) {
        props.onClose()
        await confirm({
          title: '',
          description: res?.message,
          hideCancelButton: true,
          confirmationText: __('ok')
        })
      }
      if (result.data.status === RESPONSE_STATUS_ERROR) {
        props.onClose()
        await confirm({
          title: '',
          description: res?.message,
          hideCancelButton: true,
          confirmationText: __('ok')
        })
      }
      formEvent('form_submit', { form_submit_text: __('submit') })
    } catch (err) {
      //
    }
    setOverlayLoading(false)
  }

  function formEvent (type, data) {
    data = {
      form_id: `${auth.user_id}_evaluate_${currentConversation.receiver_id}`,
      form_name: `Evaluate ${currentConversation.receiver.full_name}`,
      ...data
    }
    if (type === 'form_start') {
      firebase.analyticsFormStartEvent(data)
    }
    if (type === 'form_submit') {
      firebase.analyticsFormSubmitEvent(data)
    }
  }

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>{__('deposit_unmask_confirmation')}</DialogTitle>
        <DialogContent sx={{ maxWidth: '100%', width: 400, boxSizing: 'border-box' }}>
          <Grid container spacing={2}>
            <Grid xs={6}>Your デポジット金額:</Grid>
            <Grid xs={6} textAlign={'right'}>{props.data.employer_point.toLocaleString('ja')}円</Grid>
            <Grid xs={6}>デポジット金額:</Grid>
            <Grid xs={6} textAlign={'right'}>{props.data.amount.toLocaleString('ja')}円</Grid>
            <Grid xs={12}><Divider/></Grid>
            <Grid xs={6}>Will Remain:</Grid>
            <Grid xs={6} textAlign={'right'}>{props.data.employer_point_will_remain.toLocaleString('ja')}円</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePayment} variant={'contained'} disabled={props.data.amount > props.data.employer_point}>
            {__('pay')}
          </Button>
          <Button onClick={() => {
            props.onClose()
            formEvent('form_submit', { form_submit_text: __('cancel') })
          }}
          >{__('cancel')}</Button>
        </DialogActions>
      </Dialog>
      <OverlayLoading open={isOverlayLoading}/>
    </div>
  )
}

export default UserPaymentDialog
