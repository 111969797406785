import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography
} from '@mui/material'
import React, { createRef, useLayoutEffect, useState } from 'react'
import axios from 'axios'
import { JOB_STATUS_INVALID, JOB_STATUS_NORMAL, JOB_STATUS_UPDATED, RESPONSE_STATUS_SUCCESS } from '../../constants.js'
import __ from '../../locales/locale.js'

function MessageJobItem (props) {
  const { message } = props
  const metaResource = message.meta_resources
  const cardRef = createRef()
  const [cardWidth, setCardWidth] = useState(350)

  const [openJobAlert, setOpenJobAlert] = useState(false)
  const [messageJobAlert, setMessageJobAlert] = useState()

  useLayoutEffect(() => {
    setCardWidth(cardRef.current.clientWidth)
  })

  const handleJobCheckStatus = async () => {
    const result = await axios.get('/job/status',
      {
        params: { id: message?.meta_resources?.id || 0 }
      }
    )
    const res = result.data
    if (res.status === RESPONSE_STATUS_SUCCESS) {
      switch (res.data.status) {
        case JOB_STATUS_NORMAL: {
          setMessageJobAlert(res.message)
          setOpenJobAlert(true)
          // window.open(message?.meta_resources?.url)
          break
        }
        case JOB_STATUS_INVALID: {
          setMessageJobAlert(res.message)
          setOpenJobAlert(true)
          break
        }
        case JOB_STATUS_UPDATED: {
          setMessageJobAlert(res.message)
          setOpenJobAlert(true)
          break
        }
        default: {}
      }
    }
  }
  return (
    <Card sx={{ maxWidth: 350, backgroundColor: 'transparent' }} ref={cardRef} variant={'outlined'}>
      <CardActionArea onClick={handleJobCheckStatus}>
        {metaResource.image ? (
          <CardMedia
            component="img"
            height={cardWidth / 1.5}
            image={metaResource.image}
          />
        ) : null}
        <CardContent>
          <Typography variant="subtitle1">
            {metaResource?.company_name}のお仕事
          </Typography>
          <Typography variant="subtitle2">
            {metaResource?.job_title}
          </Typography>
          <Typography variant="subtitle2">
            {metaResource?.prefecture} {metaResource?.employment_type}
          </Typography>
        </CardContent>
      </CardActionArea>
      <Dialog open={openJobAlert}>
        <DialogContent>
          <DialogContentText>{messageJobAlert}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => ''}>
            {__('details_page_information')}
          </Button>
          <Button onClick={() => {setOpenJobAlert(false)}} autoFocus>{__('close')}</Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default MessageJobItem
