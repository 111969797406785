import React, { useEffect } from 'react'
import axios from 'axios'
import './css/App.css'
import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AutoLoginPage from './pages/AutoLoginPage'
import ChatBoxPage from './pages/ChatBoxPage'
import LoginPage from './pages/LoginPage'
import { socket, SocketContext } from './contexts/socket'
import { firebase, FirebaseContext } from './contexts/firebase'
import theme from './themes/mui'
import { ThemeProvider } from '@emotion/react'
import { setUserId, setUserProperties } from 'firebase/analytics'
import { analytics } from './firebase.js'
import { USER_TYPE_TO_NAME } from './constants.js'
import { ConfirmProvider } from 'material-ui-confirm'
import __ from './locales/locale'

const endpointApi = process.env.REACT_APP_GLOBAL_API_ENDPOINT
axios.defaults.baseURL = endpointApi
axios.defaults.headers.post['Content-Type'] = 'application/json'

function App () {
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth
  })

  const auth = useSelector(state => state.auth)

  useEffect(() => {
    try {
      if (auth.access_jwt_token) {
        setUserId(analytics, auth.user_id ?? 'guest')
        setUserProperties(analytics, {
          id: USER_TYPE_TO_NAME[auth.type] + '_' + auth.external_id,
          chat_id: auth.user_id
        })
      }
    } catch (err) {}

    axios.interceptors.response.use(null, function (error) {
      if (error.response.status === 401) {
        axios.post('/device/token/remove', {
          device_token: auth.fcm_token
        })
        localStorage.removeItem('access_jwt_token')
        localStorage.removeItem('access_jwt_token_expired')
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        localStorage.removeItem('user_id')
        localStorage.removeItem('fcm_token')
        window.location.href = '/login'
        return
      }
      return Promise.reject(error)
    })
  }, [auth.access_jwt_token, auth.external_id, auth.type, auth.user_id])

  axios.defaults.headers.common['X-Token'] = auth.access_jwt_token
  axios.defaults.headers.common['X-Api-Key'] = process.env.REACT_APP_GLOBAL_API_KEY

  useEffect(() => {
    function handleResize () {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }

    window.addEventListener('resize', handleResize)
    return _ => {
      window.removeEventListener('resize', handleResize)

    }
  })
  return (
    <SocketContext.Provider value={socket}>
      <FirebaseContext.Provider value={firebase}>
        <ThemeProvider theme={theme}>
          <ConfirmProvider
            defaultOptions={{
              confirmationText: __('yes'),
              cancellationText: __('no'),
              buttonOrder: ['confirm', 'cancel'],
              confirmationButtonProps: {
                variant: 'contained'
              },
              cancellationButtonProps: {
                autoFocus: true
              },
              allowClose: false
            }}
          >
            <BrowserRouter dimensions={dimensions}>
              <Routes>
                <Route exact={false} path="/*" element={<ChatBoxPage/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/logout" element={<LoginPage/>}/>
                <Route path="/auto-login" element={<AutoLoginPage/>}/>
                <Route path="/chat/auto-login" element={<AutoLoginPage/>}/>
              </Routes>
            </BrowserRouter>
          </ConfirmProvider>
        </ThemeProvider>
      </FirebaseContext.Provider>
    </SocketContext.Provider>
  )
}

export default App
