import { Backdrop, Box, CircularProgress, LinearProgress, Paper, Stack } from '@mui/material'
import React from 'react'
import __ from '../locales/locale'

function OverlayLoading (props) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={props.open}
      onClick={props.onClick}
    >
      <Paper elevation={3} variant={'outlined'} sx={{ p: 2 }}>
        <LinearProgress/>
        <Box marginTop={2}>{__('overlay_loading_content')}</Box>
      </Paper>
    </Backdrop>
  )
}

export default OverlayLoading
