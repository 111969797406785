import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import __ from '../locales/locale'
import React from 'react'

function MessageBoxDialog (props) {

  return (
    <Dialog open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent sx={{ maxWidth: '100%', width: 800, boxSizing: 'border-box' }}>
        {props.content}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{__('close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default MessageBoxDialog
