import React, { useContext, useEffect, useState } from 'react'
import {
  Avatar,
  ChatContainer,
  ConversationHeader,
  MessageInput,
  MessageList,
  TypingIndicator
} from '@chatscope/chat-ui-kit-react'
import * as uuid from 'uuid'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import {
  appendMessageForConversation,
  changeCurrentConversation,
  openSidebar,
  prependMessageForConversation,
  setConversationUserStatus,
  updateConversation,
  updateMessageStatus
} from '../redux/reducers/Chatbox.reducer'
import moment from 'moment'
import {
  INFO_PERMISSION_APPROVED,
  INFO_PERMISSION_REJECTED,
  RESPONSE_STATUS_ERROR,
  RESPONSE_STATUS_SUCCESS,
  USER_STATUS_BANNED,
  USER_STATUS_DELETED,
  USER_STATUS_PENDING_DELETE,
  USER_TYPE_HCW,
  USER_TYPE_MED
} from '../constants.js'
import { __ } from '../locales/locale'
import ChatBoxMessageList from './ChatBoxMessageList'
import { SocketContext } from '../contexts/socket'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Rating,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import {
  changeContentMessageTemplate,
  changeSelectMessageTemplate
} from '../redux/reducers/MessageTemplateForm.reducer'
import { changeMessageInputText } from '../redux/reducers/MessageInput.reducer'

import * as MaterialIcon from '@mui/icons-material'
import UserEvaluationDialog from './UserEvaluationDialog'
import { openMessageBox } from '../redux/reducers/MessageBox.reducer.js'
import OverlayLoading from '../common_components/OverlayLoading'
import { useConfirm } from 'material-ui-confirm'
import BasicMenu from '../common_components/BasicMenu'

let timeout
let isEnded = false
let isLoading = false

function ChatBoxContainer () {
  const socket = useContext(SocketContext)

  const isSidebarOpened = useSelector(state => state.chatbox.isSidebarOpened)
  const messages = useSelector((state) => state.chatbox.currentConversation.messages)
  const currentConversation = useSelector((state) => state.chatbox.currentConversation)
  const auth = useSelector((state) => state.auth)
  const global = useSelector((state) => state.global)
  const [isOpenMenu, setOpenMenu] = React.useState(null)
  const open = Boolean(isOpenMenu)

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setOpenMenu(null)
  }
  const confirm = useConfirm()
  const messageTemplateForm = useSelector((state) => state.messageTemplateForm)
  const messageText = useSelector((state) => state.messageInput.message)
  const dispatch = useDispatch()

  const [openMessageTemplate, setOpenMessageTemplate] = React.useState(false)
  const [isLoadingMore, setLoadingMore] = useState(false)
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [isTyping, setIsTyping] = useState(false)
  const [chatContainerStyle, setChatContainerStyle] = useState({})
  const [openUserRating, setOpenUserRating] = useState(false)
  const [isOverlayLoading, setOverlayLoading] = useState(false)
  let employeeId = 0
  if (currentConversation?.receiver?.type === USER_TYPE_HCW) {
    employeeId = currentConversation.receiver.external_id
  }
  useEffect(() => {
    dispatch(changeCurrentConversation(currentConversation))
    isEnded = false
    setIsFirstLoading(true)
    loadMessage()
    if (socket) {
      socket.emit('user online', { user_id: currentConversation.receiver_id }, function (ack) {
        dispatch(setConversationUserStatus({
          user_id: currentConversation.receiver_id,
          is_online: ack.is_online === 1
        }))
      })
    }
    if (currentConversation.id === '') {
      setIsFirstLoading(false)
    }
  }, [currentConversation.id])

  useEffect(() => {
    if (isSidebarOpened) {
      setChatContainerStyle({
        display: 'none'
      })
    } else {
      setChatContainerStyle({})
    }
  }, [isSidebarOpened])

  if (socket) {
    socket.off('private message').on('private message', function (data) {
      appendMessage(data)
    })

    socket.off('private message receive').on('private message receive', function (data) {
      appendMessage(data)
      setIsTyping(false)
      if (currentConversation.id === data.conversation_id) {
        socket.emit('conversation read', { conversation_id: data.conversation_id }, function (ack) {
          if (ack.status === RESPONSE_STATUS_SUCCESS) {
            dispatch(updateConversation(ack.data))
          }
        })
      } else {
        if (Notification.permission === 'granted') {
          const notificationTitle = data.sender_name
          const notificationOptions = {
            body: data.message,
            icon: data.sender_avatar || '/user-female.png',
            tags: data.conversation_id,
            timestamp: data.created_at
          }
          new Notification(notificationTitle, notificationOptions)
        }
      }
    })

    socket.off('conversation typing').on('conversation typing', (data) => {
      if (data.sender_id === currentConversation.receiver_id) {
        setIsTyping(true)
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          setIsTyping(false)
        }, 3000)
      }
    })
  }

  function loadMessage () {
    if (isLoading) return
    if (currentConversation.id === '') return
    if (isEnded) return

    setLoadingMore(true)
    isLoading = true
    setTimeout(async () => {
      const response = await axios.get('/chat/messages', {
        params: {
          timestamp_position: messages.length > 0 ? messages[0].timestamp_position : 0,
          conversation_id: currentConversation.id,
          display: 50
        }
      })
      const result = response.data
      if (result.status === 'success') {
        isEnded = result?.data?.length <= 0
        dispatch(prependMessageForConversation(result.data))
      }
      setLoadingMore(false)
      setIsFirstLoading(false)
      setTimeout(() => {
        isLoading = false
      }, 100)
    })
  }

  function sendMessage (customText = '') {
    if (currentConversation.id === '') return
    let text
    if (customText) {
      text = customText.trim()
      text = text.replaceAll('<br>', '\r\n')
    } else {
      text = messageText.trim()
      text = text.replaceAll('<br>', '\r\n')
      dispatch(changeMessageInputText(''))
    }

    if (!text) return

    const data = {
      id: uuid.v4(),
      message: text,
      conversation_id: currentConversation.id,
      type: 1,
      sender_id: auth.user_id * 1,
      direction: 'outgoing',
      created_at: Date.now() / 1000,
      status: 1
    }

    appendMessage(data)

    if (socket) {
      if (socket.connected) {
        socket.emit('private message', data, (ack) => {
          if (ack.status === RESPONSE_STATUS_SUCCESS) {
            dispatch(updateMessageStatus({ ...data, status: 0 }))
            socket.emit('conversation read', { conversation_id: data.conversation_id }, (ack) => {
              if (ack.status === RESPONSE_STATUS_SUCCESS) {
                dispatch(updateConversation(ack.data))
              }
              if (ack.status === RESPONSE_STATUS_ERROR) {

              }
            })
          }
          if (ack.status === RESPONSE_STATUS_ERROR) {
            dispatch(updateMessageStatus({ ...data, status: 2, status_error_message: ack.message }))
          }
        })
      } else {
        dispatch(updateMessageStatus({ ...data, status: 2, status_error_message: __('lost_connection') }))
      }
    } else {
      dispatch(updateMessageStatus({ ...data, status: 2, status_error_message: __('lost_connection') }))
    }
  }

  function appendMessage (message) {
    if (currentConversation.id === message.conversation_id) {
      dispatch(appendMessageForConversation(message))
    }
  }

  function onTyping () {
    if (messageText && socket) {
      socket.emit('conversation typing', {
        conversation_id: currentConversation.id,
        receiver_id: currentConversation.receiver_id
      })
    }
  }

  function getLastActivity () {
    if (currentConversation.id === '') return ''
    if (!currentConversation.is_online) {
      if (currentConversation.receiver_last_time_at > 0) {
        return moment.unix(currentConversation.receiver_last_time_at).fromNow()
      }
      return ''
    }
    return __('online')
  }

  const handleBackClick = () => {
    dispatch(openSidebar())
  }

  function onOpenMessageTemplate () {
    setOpenMessageTemplate(true)
  }

  function onSelectTemplateMessage (e) {
    dispatch(changeSelectMessageTemplate(global.messageTemplates.find(a => a.id === e.target.value)))
  }

  function onChangeContentTemplateMessage (e) {
    dispatch(changeContentMessageTemplate({ content: e.target.value || '' }))
  }

  function onSendMessageTemplate () {
    setOpenMessageTemplate(false)
    sendMessage(messageTemplateForm.messageTemplateContent)
    dispatch(changeSelectMessageTemplate({ id: '', content: '' }))
  }

  function onCloseMessageTemplate () {
    setOpenMessageTemplate(false)
    dispatch(changeSelectMessageTemplate({ id: '', content: '' }))
  }

  const getInputComponent = () => {
    const userReceiverStatus = parseInt(currentConversation?.receiver?.status || 0)

    switch (userReceiverStatus) {
      case USER_STATUS_BANNED : {
        return (
          <div as={MessageInput} style={styles.messageInput}>
            You can not contact with this user. This user is deleted
          </div>
        )
      }
      case USER_STATUS_DELETED:
      case USER_STATUS_PENDING_DELETE: {
        return (
          <div as={MessageInput} style={styles.messageDisableInput}>
            You can not contact with this user.
          </div>
        )
      }
      default: {}
    }
    return (
      <div as={MessageInput} style={styles.messageInput}>
        {
          auth.type === USER_TYPE_MED ?
            (
              <Stack spacing={2} direction={'row'} sx={{ marginLeft: 1 }}>
                <Tooltip title={__('message_templates')}>
                    <span>
                      <IconButton
                        onClick={onOpenMessageTemplate}
                        disabled={currentConversation.id === '' || global.messageTemplates.length === 0}
                      >
                      <MaterialIcon.InsertComment/>
                    </IconButton>
                    </span>
                </Tooltip>
              </Stack>
            )
            : null
        }
        <FormControl fullWidth>
          <OutlinedInput
            multiline
            sx={{
              margin: '10px',
              backgroundColor: '#c6e3fa',
              borderRadius: '0.7em'
            }}
            maxRows={5}
            placeholder={__('type_message_here')}
            value={messageText}
            onPaste={(e) => {dispatch(changeMessageInputText(messageText + e.target.innerText))}}
            onChange={(e) => dispatch(changeMessageInputText(e.target.value))}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                sendMessage()
              }
              onTyping()
            }}
            size={'small'}
            disabled={currentConversation.id === ''}
          />
        </FormControl>
        <Stack spacing={2} direction={'row'} sx={{ marginRight: 1 }}>
          <IconButton
            color={!!messageText ? 'primary' : 'default'}
            onClick={() => sendMessage()}
            disabled={currentConversation.id === '' || !messageText}
          >
            <MaterialIcon.Send/>
          </IconButton>
        </Stack>
      </div>
    )
  }

  const openRequestPermissionConfirm = () => {
    confirm({
      title: '',
      description: __('confirm_message_send_the_request_information_permission'),
      confirmationText: __('submit'),
    })
      .then(handleSubmitRequestPermission)
      .catch(null)
  }
  const handleSubmitRequestPermission = async () => {
    setOverlayLoading(true)
    try {
      const result = await axios.post('/employer/information/permission/request', { employee_id: employeeId })
      const res = result.data
      if (res.status === RESPONSE_STATUS_SUCCESS) {
        await confirm({
          title: '',
          description: res?.message,
          hideCancelButton: true,
          confirmationText: __('ok')
        })
      } else {
        await confirm({
          title: '',
          description: res?.message,
          hideCancelButton: true,
          confirmationText: __('ok')
        })
      }
    } catch (e) {

    }
    setOverlayLoading(false)
  }

  const menuItems = []
  if (currentConversation.id !== '' && currentConversation.is_unmasked && currentConversation.messages.length >= 5) {
    menuItems.push({
      title: __('evaluation'),
      action: () => setOpenUserRating(true),
      icon: <MaterialIcon.RateReview/>
    })
  }
  if (auth.type === USER_TYPE_MED
    && currentConversation.info_permission_status !== INFO_PERMISSION_APPROVED
    && currentConversation.info_permission_status !== INFO_PERMISSION_REJECTED) {
    menuItems.push({
      title: __('unmask'),
      action: openRequestPermissionConfirm,
      icon: <MaterialIcon.PrivacyTipRounded/>
    })
  }

  return (
    <div style={{ ...chatContainerStyle, width: '100%' }}>
      <ChatContainer style={chatContainerStyle}>
        <ConversationHeader>
          <ConversationHeader.Back>
            <Tooltip title={__('conversation')}>
              <IconButton onClick={handleBackClick}>
                <MaterialIcon.People fontSize={'large'}/>
              </IconButton>
            </Tooltip>
          </ConversationHeader.Back>
          <ConversationHeader.Content style={{ display: currentConversation.id !== '' ? 'block' : 'none' }}>
            <Stack direction={'row'} spacing={2}>
              <Avatar
                src={currentConversation.avatar}
                name={currentConversation.name}
                status={currentConversation.is_online ? 'unavailable' : 'available'}
              />
              <Stack>
                <Stack direction={'row'}>
                  <Typography color={'primary'} variant={'subtitle1'}>{currentConversation.name}</Typography>
                </Stack>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  <Rating
                    style={{ fontSize: 12 }}
                    value={currentConversation?.receiver_evaluation_average || 0}
                    precision={0.5} readOnly
                  />
                  <Typography color={'secondary'} variant={'caption'}>{getLastActivity()}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </ConversationHeader.Content>
          <ConversationHeader.Actions>
            <BasicMenu items={menuItems}/>
          </ConversationHeader.Actions>
        </ConversationHeader>
        <ChatBoxMessageList
          as={MessageList}
          disableOnYReachWhenNoScroll={true}
          onYReachStart={loadMessage}
          loadingMore={isLoadingMore}
          loading={isFirstLoading}
          typingIndicator={isTyping ? <TypingIndicator/> : null}
        />
        {getInputComponent()}
      </ChatContainer>
      <Dialog open={openMessageTemplate}>
        <DialogTitle>{__('message_templates')}</DialogTitle>
        <DialogContent sx={{ maxWidth: '100%', width: 800, boxSizing: 'border-box' }}>
          <TextField
            select label={__('template_name')}
            fullWidth variant="outlined" margin={'normal'}
            value={messageTemplateForm?.messageTemplateId || ''}
            onChange={onSelectTemplateMessage}
            placeholder={__('template_name')}
          >
            {global.messageTemplates.map((row) => {
              return <MenuItem key={row.id} value={row.id}>{row?.title}</MenuItem>
            })}
          </TextField>
          <TextField
            multiline={true} rows={5} label={__('template_body')}
            fullWidth variant="outlined" margin={'normal'}
            value={messageTemplateForm?.messageTemplateContent}
            onChange={onChangeContentTemplateMessage}
            placeholder={__('template_default_message')}
            focused
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onSendMessageTemplate} variant="contained">{__('submit')}</Button>
          <Button onClick={onCloseMessageTemplate}>{__('cancel')}</Button>
        </DialogActions>
      </Dialog>
      <UserEvaluationDialog
        open={openUserRating}
        onClose={() => setOpenUserRating(false)}
        onSuccess={() => dispatch(openMessageBox(
            {
              title: __('alert'),
              content: __('success')
            }
          )
        )}
      />
      <OverlayLoading open={isOverlayLoading}/>
    </div>
  )
}

const styles = {
  messageInput: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px dashed #d1dbe4',
    alignItems: 'center'
  },
  messageDisableInput: {
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}
export default ChatBoxContainer
