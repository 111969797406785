import React from 'react'
import { Message, MessageList, MessageSeparator } from '@chatscope/chat-ui-kit-react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { __ } from '../locales/locale'
import ChatBoxMessageListItem from './ChatBoxMessageListItem'

function ChatBoxMessageList (props) {
  const currentConversation = useSelector((state) => state.chatbox.currentConversation)
  const { messages } = currentConversation

  function getMessagePosition (idx) {
    const data = messages[idx]
    let position = 'normal'
    let senderIdPrevious = 0
    let senderIdNext = 0
    if ((idx + 1) < messages.length) senderIdNext = messages[idx + 1].sender_id * 1 || 0
    if ((idx - 1) >= 0) senderIdPrevious = messages[idx - 1].sender_id * 1 || 0

    if (data.sender_id !== senderIdNext) {
      position = 'first'
    }
    if ((idx - 1) >= 0 && data.sender_id !== senderIdPrevious) {
      position = 'last'
    }

    const dateCurrent = moment.unix(data.created_at).format('YYYYMMDD')
    const datePrevious = moment.unix(messages[idx > 0
      ? idx - 1
      : 0].created_at).format('YYYYMMDD')
    const dateNext = moment.unix(messages[(idx < messages.length - 1)
      ? (idx + 1)
      : (messages.length - 1)].created_at).format('YYYYMMDD')
    if (idx > 0 && dateCurrent > datePrevious) {
      position = 'last'
    }

    if (idx < messages.length - 1 && dateCurrent < dateNext) {
      position = 'first'
    }

    if (senderIdNext === senderIdPrevious && senderIdNext !== data.sender_id) position = 'single'
    if (idx === (messages.length - 1)) position = 'first'
    if (idx === 0) position = 'last'
    return position
  }

  function builderMessageListComponent () {
    const builderComponents = []
    messages.map((data, idx) => {
      let separator = null
      if (idx === 0) {
        separator =
          <MessageSeparator key={'separator' + data.created_at}>{moment.unix(data.created_at).
            calendar()}</MessageSeparator>
      }
      if (idx > 0 && moment.unix(data.created_at).
        format('YYYYMMDD') > moment.unix(messages[idx - 1].created_at).
        format('YYYYMMDD')) {
        separator =
          <MessageSeparator key={'separator' + data.created_at}>{moment.unix(data.created_at).
            calendar()}</MessageSeparator>
      }
      if (separator) {
        builderComponents.push(separator)
      }
      builderComponents.push(
        <ChatBoxMessageListItem as={Message} key={data.id} message={data} position={getMessagePosition(idx)} />)

    })
    return builderComponents
  }

  function builderMessageListEmptyComponent () {
    return (
      <MessageList.Content style={{
        display: 'flex', 'flexDirection': 'column', 'justifyContent': 'center', height: '100%', textAlign: 'center', fontSize: '1.2em'
      }}>
        {currentConversation.id === ''
          ? __('choose_conversation_message_welcome')
          : __('first_message_welcome')}
      </MessageList.Content>
    )
  }

  return <MessageList {...props}>
    {
      (messages.length > 0 && currentConversation.id !== '')
        ? builderMessageListComponent()
        : builderMessageListEmptyComponent()
    }
  </MessageList>
}

export default ChatBoxMessageList
