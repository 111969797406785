import React, { useEffect, useState } from 'react'
import '../css/LoginForm.css'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { RESPONSE_STATUS_ERROR, RESPONSE_STATUS_SUCCESS } from '../constants'
import __ from '../locales/locale'

let autoLoginTimeout
export default function AutoLoginPage () {
  let [searchParams] = useSearchParams()
  let [errorMessage, setErrorMessage] = useState()

  const token = searchParams.get('token')
  useEffect(() => {
    clearTimeout(autoLoginTimeout)
    autoLoginTimeout = setTimeout(async () => {
      axios.post(process.env.REACT_APP_ENDPOINT_API + '/auth/login/auto', { token }).then(result => {
        const res = result.data
        if (res.status === RESPONSE_STATUS_ERROR) {
          return setErrorMessage(res.message)
        }
        if (res.status === RESPONSE_STATUS_SUCCESS) {
          localStorage.setItem('access_jwt_token', res.data?.access_jwt_token)
          localStorage.setItem('access_jwt_token_expired', res.data?.access_jwt_token_expired)
          localStorage.setItem('access_token', res.data?.access_token)
          localStorage.setItem('refresh_token', res.data?.refresh_token)
          localStorage.setItem('user_id', res.data?.id)
          localStorage.setItem('external_id', res.data?.external_id)
          localStorage.setItem('type', res.data?.type)

          if (res.data.only_login) {
            window.location.href = '/'
            return
          }

          axios.post(process.env.REACT_APP_ENDPOINT_API + '/conversations/private/new', { token }, { headers: { 'Authorization': res.data.access_jwt_token } },
          ).then(result => {
            const resConversation = result.data

            if (resConversation.status === RESPONSE_STATUS_ERROR) {
              localStorage.removeItem('access_jwt_token')
              localStorage.removeItem('access_jwt_token_expired')
              localStorage.removeItem('access_token')
              localStorage.removeItem('refresh_token')
              localStorage.removeItem('user_id')
              localStorage.removeItem('external_id')
              localStorage.removeItem('type')
              setErrorMessage(resConversation.message)
              return
            }
            if (resConversation.status === RESPONSE_STATUS_SUCCESS) {
              window.location.href = '/chat/' + resConversation.data.id
            }
          })
        }
      })
    }, 0)
  }, [])

  async function handleClose () {
    window.location.href = '/login'
  }

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ maxWidth: 500, width: '100%' }}>
        <div style={{ textAlign: 'center' }}>
          {errorMessage ? '' : <CircularProgress />}
        </div>
      </div>
      <Dialog
        open={!!errorMessage}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant={'contained'}>
            {__('login')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
