import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import __ from '../locales/locale'
import React, { useEffect } from 'react'

function DeleteConversationConfirmDialog (props) {

  useEffect(() => {}, [])

  return (
    <Dialog open={props.open}>
      <DialogContent sx={{ maxWidth: '100%', width: 300, boxSizing: 'border-box' }}>
        <DialogContentText>{__('do_you_really_delete_this_conversation')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onAccepted} variant={'contained'}>{__('yes')}</Button>
        <Button onClick={props.onClose}>{__('no')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteConversationConfirmDialog
