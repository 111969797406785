import { createSlice } from '@reduxjs/toolkit'
import __ from '../../locales/locale.js'

export const defaultSlice = createSlice({
  name: 'auth',
  initialState: {
    hcwSettings: [
      {
        group: __('push_notification_setting'),
        notice: __('push_notification_setting_notice'),
        keys: [
          {name: 'receive_push_regular_chat_message', title: __('receive_push_regular_chat_message')},
          {name: 'receive_push_scout', title: __('receive_push_scout')},
        ]
      },
      {
        group: __('email_notification_setting'),
        notice: __('email_notification_setting_notice'),
        keys: [
          {name: 'receive_email_regular_chat_message', title: __('receive_email_regular_chat_message')},
          {name: 'receive_email_scout', title: __('receive_email_scout')},
          {name: 'receive_email_recommend_job', title: __('receive_email_recommend_job')},
          {name: 'receive_email_cv_changed', title: __('receive_email_cv_changed')},
          {name: 'receive_email_news_and_promotions', title: __('receive_email_news_and_promotions')},
        ]
      }
    ],
    medSettings: [
      {
        group: __('push_notification_setting'),
        notice: __('push_notification_setting_notice'),
        keys: [
          {name: 'receive_push_regular_chat_message', title: __('receive_push_regular_chat_message')},
          {name: 'receive_push_cv', title: __('receive_push_cv')},
        ]
      },
      {
        group: __('email_notification_setting'),
        notice: __('email_notification_setting_notice'),
        keys: [
          {name: 'receive_email_regular_chat_message', title: __('receive_email_regular_chat_message')},
          {name: 'receive_email_cv', title: __('receive_email_cv')},
          {name: 'receive_email_recommend_information', title: __('receive_email_recommend_information')},
          {name: 'receive_email_contract_and_billing', title: __('receive_email_contract_and_billing')},
          {name: 'receive_email_facilities_and_jobs', title: __('receive_email_facilities_and_jobs')},
          {name: 'receive_email_news_and_promotions', title: __('receive_email_news_and_promotions')},
        ]
      }
    ],
    setting: {
      receive_push_regular_chat_message: 1,
      receive_push_cv: 1,
      receive_push_scout: 1,
      receive_email_regular_chat_message: 1,
      receive_email_scout: 1,
      receive_email_recommend_job: 1,
      receive_email_cv_changed: 1,
      receive_email_news_and_promotions: 1,
      receive_email_cv: 1,
      receive_email_recommend_information: 1,
      receive_email_contract_and_billing: 1,
      receive_email_facilities_and_jobs: 1
    },
    saving: {
      receive_push_regular_chat_message: false,
      receive_push_cv: false,
      receive_push_scout: false,
      receive_email_regular_chat_message: false,
      receive_email_scout: false,
      receive_email_recommend_job: false,
      receive_email_cv_changed: false,
      receive_email_news_and_promotions: false,
      receive_email_cv: false,
      receive_email_recommend_information: false,
      receive_email_contract_and_billing: false,
      receive_email_facilities_and_jobs: false
    }
  },
  reducers: {
    setUserSetting: (state, action) => {
      state.setting = {...state.setting, ...action.payload}
    },
    setSavingLoading: (state, action) => {
      state.saving = {...state.saving, ...action.payload}
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setUserSetting,
  setSavingLoading
} = defaultSlice.actions

export default defaultSlice.reducer
