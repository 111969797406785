import { createSlice } from '@reduxjs/toolkit'

export const defaultSlice = createSlice({
  name: 'messageBox',
  initialState: {
    isOpen: false,
    title: '',
    content: ''
  },
  reducers: {
    openMessageBox: (state, action) => {
      state.isOpen = true
      state.title = action.payload.title
      state.content = action.payload.content
      console.log(action);
    },
    closeMessageBox: (state, action) => {
      state.isOpen = false
      state.title = ''
      state.content = ''
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  openMessageBox,
  closeMessageBox,
} = defaultSlice.actions

export default defaultSlice.reducer
