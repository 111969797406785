import { createSlice } from '@reduxjs/toolkit'

export const defaultSlice = createSlice({
  name: 'global',
  initialState: {
    messageTemplates: []
  },
  reducers: {
    updateMessageTemplates: (state, action) => {
      state.messageTemplates = [...action.payload]
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  updateMessageTemplates
} = defaultSlice.actions

export default defaultSlice.reducer
