import { configureStore } from '@reduxjs/toolkit'
import chatBoxReducer from './reducers/Chatbox.reducer'
import authReducer from './reducers/Auth.reducer'
import globalReducer from './reducers/Global.reducer'
import MessageTemplateFormReducer from './reducers/MessageTemplateForm.reducer'
import MessageInput from './reducers/MessageInput.reducer'
import UIScopeReducer from './reducers/UIScope.reducer'
import MessageBoxReducer from './reducers/MessageBox.reducer.js'
import UserSettingReducer from './reducers/UserSetting.reducer.js'

export default configureStore({
  reducer: {
    chatbox: chatBoxReducer,
    auth: authReducer,
    global: globalReducer,
    messageTemplateForm: MessageTemplateFormReducer,
    messageInput: MessageInput,
    uiScope: UIScopeReducer,
    messageBox: MessageBoxReducer,
    userSetting: UserSettingReducer,
  }
})
