import React from 'react'
import '../css/LoginForm.css'
import { Button, Stack, Typography } from '@mui/material'
import __ from '../locales/locale'

export default function LoginPage () {
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ maxWidth: '95%', width: 400 }}>
        <div style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: 50, textAlign: 'center' }}>
            <Typography variant='h4'>{__('login_description')}</Typography>
          </div>
          <Stack spacing={2}>
            <Button href={`${process.env.REACT_APP_MED_URL}/login`} variant='contained'>
              {__('med')}
            </Button>
            <Button href={`${process.env.REACT_APP_HCW_URL}/login`} variant='contained'>
              {__('hcw')}
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
}
