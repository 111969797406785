import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Switch
} from '@mui/material'
import __ from '../locales/locale'
import React, { useEffect } from 'react'
import axios from 'axios'
import { RESPONSE_STATUS_ERROR, RESPONSE_STATUS_SUCCESS, USER_TYPE_MED } from '../constants.js'
import { useDispatch, useSelector } from 'react-redux'
import { setSavingLoading, setUserSetting } from '../redux/reducers/UserSetting.reducer.js'
import { IOSSwitch } from '../common_components/Switch'

function UserSettingDialog (props) {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const userSetting = useSelector((state) => state.userSetting)

  useEffect(() => {
    async function getSetting () {
      const result = await axios.get('/me/setting')
      const setting = result.data.data
      dispatch(setUserSetting(setting))
    }

    getSetting()
  }, [])

  async function onUpdateSetting (field, value) {
    const saving = {}
    saving[field] = true
    dispatch(setSavingLoading(saving))

    const postFields = {}
    value = Math.abs(value - 1)
    postFields[field] = value

    let updated = false
    try {
      const result = await axios.post('/me/setting/update', postFields)
      updated = result.data.status === RESPONSE_STATUS_SUCCESS

      if (result.data.status === RESPONSE_STATUS_ERROR) {

      }
    } catch (err) {

    }

    setTimeout(() => {
      if (updated) {
        const setting = {}
        setting[field] = value
        dispatch(setUserSetting(setting))
      }
      saving[field] = false
      dispatch(setSavingLoading(saving))
    }, 300)
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle>{__('notification_settings')}</DialogTitle>
      <DialogContent sx={{ maxWidth: '100%', width: 800, boxSizing: 'border-box' }}>
        {
          (auth.type === USER_TYPE_MED ? userSetting.medSettings : userSetting.hcwSettings).map((settingGroup, idx) => {
            return (
              <FormControl
                component="fieldset" variant="standard" fullWidth sx={{ marginBottom: 2 }}
                key={`SettingGroup_${idx}`}>
                <FormLabel component="legend">{settingGroup.group}</FormLabel>
                <FormGroup>
                  {settingGroup.keys.map((key) => {
                    return (
                      <div style={{ margin: '5px 0' }} key={key.name}>
                        <FormControlLabel
                          key={key.name}
                          control={
                            <IOSSwitch
                              sx={{ marginLeft: 1, marginRight: 1 }}
                              value={userSetting.setting[key.name]}
                              checked={userSetting.setting[key.name] === 1}
                              name={key.name}
                              disabled={userSetting.saving[key.name]}
                              onChange={async (e) => {
                                await onUpdateSetting(key.name, userSetting.setting[key.name])
                              }}
                            />
                          }
                          label={key.title}
                        />
                      </div>
                    )
                  })}
                </FormGroup>
                <FormHelperText>{settingGroup.notice}</FormHelperText>
              </FormControl>
            )
          })
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{__('close')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserSettingDialog
