import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Conversation, ConversationList, Search, Sidebar } from '@chatscope/chat-ui-kit-react'
import axios from 'axios'
import avatarIcon from '../assets/user.png'
import logoIcon from '../assets/logo90.png'
import {
  activeCurrentConversation,
  changeCurrentConversation,
  clearSelectConversation,
  closeSidebar,
  findConversation,
  loadMoreConversation,
  removeConversation,
  setConversationUserStatus,
  updateConversation
} from '../redux/reducers/Chatbox.reducer'
import { SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type } from 'react-swipeable-list'
import 'react-swipeable-list/dist/styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { RESPONSE_STATUS_ERROR, RESPONSE_STATUS_SUCCESS } from '../constants'
import __ from '../locales/locale'
import { SocketContext } from '../contexts/socket'
import { Button, IconButton, Tooltip } from '@mui/material'
import UserSettingDialog from './UserSettingDialog'
import * as MaterialIcon from '@mui/icons-material'
import DeleteConversationConfirmDialog from './DeleteConversationConfirmDialog.js'
import { useNavigate } from 'react-router-dom'
import { FirebaseContext } from '../contexts/firebase.js'

let timeout
let firstLoad = true
let searchTimeout //Timeout Stop call API

function ChatBoxSideBar () {
  const firebase = useContext(FirebaseContext)
  const navigate = useNavigate()
  const socket = useContext(SocketContext)
  const dispatch = useDispatch()
  const conversations = useSelector((state) => state.chatbox.conversations)
  const isSidebarOpened = useSelector((state) => state.chatbox.isSidebarOpened)
  const auth = useSelector((state) => state.auth)

  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [sidebarStyle, setSidebarStyle] = useState({})
  const [conversationContentStyle, setConversationContentStyle] = useState({})
  const [openUserSetting, setOpenUserSetting] = useState(false)
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [deletionConversation, setDeletionConversation] = useState(null)
  let currentConversationId = window.location.pathname.replaceAll('/chat', '').substring(1)

  if (socket) {
    socket.off('conversation update').on('conversation update', function (data) {
      dispatch(updateConversation(data))
    })

    socket.off('user online').on('user online', function (data) {
      dispatch(setConversationUserStatus({ user_id: data.user_id, is_online: true }))
    })
    socket.off('user offline').on('user offline', function (data) {
      dispatch(setConversationUserStatus({ user_id: data.user_id, is_online: false }))
    })
  }

  useEffect(() => {
    firstLoad = true
    loadConversation()
  }, [])// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (firstLoad) {
      if (currentConversationId) {
        const found = conversations.find(a => a.id === currentConversationId)
        if (found) {
          openConversation(found)
        }
      }
    }
  })
  useEffect(() => {
    if (isSidebarOpened) {
      setSidebarStyle({
        display: 'flex',
        flexBasis: 'auto',
        width: '100%',
        maxWidth: '100%'
      })
      setConversationContentStyle({
        display: 'flex'
      })
    } else {
      setSidebarStyle({})
      setConversationContentStyle({})
    }
  }, [isSidebarOpened])
  const loadConversation = () => {
    clearTimeout(timeout)
    timeout = setTimeout(async () => {
      const response = await axios.get('/chat/conversations', {
        params: {
          timestamp_position: conversations[0]?.timestamp_position
        }
      })
      const result = response.data
      dispatch(loadMoreConversation(result.data))
      setIsFirstLoading(false)
    }, 300)
  }

  function openConversation (data) {
    if (socket) {
      socket.emit('conversation read', { conversation_id: data.id }, (ack) => {
        if (ack.status === RESPONSE_STATUS_SUCCESS) {
          dispatch(updateConversation(ack.data))
        }
      })
    }

    if (data.id === currentConversationId && !firstLoad) {
      return
    }
    dispatch(closeSidebar())
    firstLoad = false
    dispatch(changeCurrentConversation(data))
    dispatch(activeCurrentConversation())
    window.history.replaceState('', data.name, data.id)

    firebase.analyticsPageViewEvent({
      page_title: data.receiver.full_name,
    })
  }

  function handleSearch (text) {
    dispatch(findConversation(text))
    if (text.length > 0) {
      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(async () => {
        await axios.get('/conversations/search', {
          params: {
            keywords: text
          }
        })
      }, 500)
    }
    firebase.analyticsSearchEvent({ search_term: text })
  }

  async function deleteConversation () {
    if (deletionConversation) {
      const result = await axios.post('/chat/conversation/delete', { conversation_id: deletionConversation.id })
      const res = result.data
      if (res.status === RESPONSE_STATUS_SUCCESS) {
        dispatch(removeConversation(deletionConversation.id))
        if (deletionConversation.id === currentConversationId) {
          dispatch(clearSelectConversation())
        }
        navigate('/chat/  ')
      }
      if (res.status === RESPONSE_STATUS_ERROR) {

      }
    }
    formDeleteEvent('form_submit', deletionConversation.id, { form_submit_text: __('yes') })
    setOpenConfirmDelete(false)
    setDeletionConversation(null)
  }

  async function logout () {
    localStorage.removeItem('access_jwt_token')
    localStorage.removeItem('access_jwt_token_expired')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('user_id')
    localStorage.removeItem('fcm_token')
    await axios.post('/device/token/remove', { device_token: auth.fcm_token })
    firebase.analyticsLogoutEvent()
    window.location.href = '/logout'
  }

  function formDeleteEvent (type, conversationId, data) {
    data = {
      form_id: `${auth.user_id}_delete_conversation_${conversationId}`,
      form_name: `Delete ${conversationId}`,
      ...data
    }
    if (type === 'form_start') {
      firebase.analyticsFormStartEvent(data)
    }
    if (type === 'form_submit') {
      firebase.analyticsFormSubmitEvent(data)
    }
  }

  return (
    <Sidebar position="left" scrollable={false} style={sidebarStyle}>
      <div style={{
        width: '100%',
        boxSizing: 'border-box',
        padding: '5px 10px',
        height: 70,
        display: 'flex',
        alignItems: 'center'
      }}>
        <a href={'/dashboard'} style={{ flex: 1 }}>
          <img src={logoIcon} style={{
            height: 50,
            width: 'auto'
          }} alt={''}/>
        </a>
        <div style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end'
        }}>
          <Tooltip title={__('home')}>
            <IconButton href={'/dashboard'}>
              <MaterialIcon.Home/>
            </IconButton>
          </Tooltip>
          <Tooltip title={__('setting')}>
            <IconButton onClick={() => setOpenUserSetting(true)}>
              <MaterialIcon.EditNotifications/>
            </IconButton>
          </Tooltip>
          <Tooltip title={__('logout')}>
            <IconButton onClick={logout}>
              <MaterialIcon.Logout/>
            </IconButton>
          </Tooltip>
          {isSidebarOpened
            ? <Tooltip title={__('close')}>
              <IconButton onClick={() => dispatch(closeSidebar())}>
                <MaterialIcon.Close/>
              </IconButton>
            </Tooltip>
            : null}
        </div>
      </div>
      <Search placeholder={__('search_conversation')} onChange={handleSearch} onClearClick={() => handleSearch('')}/>
      <SwipeableList
        loading={isFirstLoading}
        type={Type.IOS}
        fullSwipe={true}
        as={ConversationList}
        style={{ direction: 'rtl' }}
      >
        {
          conversations.map((data) => {
            return (
              <SwipeableListItem
                as={Conversation}
                key={data.id}
                blockSwipe={!isSidebarOpened}
                trailingActions={
                  <TrailingActions>
                    <SwipeAction
                      onClick={() => {
                        setOpenConfirmDelete(true)
                        setDeletionConversation(data)
                        formDeleteEvent('form_start', data.id)
                      }}
                    >
                      <Tooltip title={__('delete')}>
                        <Button
                          onClick={() => {}}
                          style={
                            {
                              backgroundColor: 'red', color: '#fff', borderRadius: 0, direction: 'ltr'
                            }
                          }
                          startIcon={<MaterialIcon.Delete/>}
                        >
                          {__('delete')}
                        </Button>
                      </Tooltip>
                    </SwipeAction>
                  </TrailingActions>
                }
              >
                <Conversation
                  style={{ width: '100%', direction: 'ltr' }}
                  className={data?.hidden || false ? 'hidden' : ''}
                  active={data.active}
                  unreadCnt={data.unread_count}
                  onClick={(e) => {
                    openConversation(data)
                  }}
                  key={data.id}
                >
                  <Avatar
                    src={data?.avatar || avatarIcon} name={data.name}

                    status={data.is_online ? 'unavailable' : 'available'}/>
                  <Conversation.Content
                    name={data.name} info={data.last_message}
                    style={{ ...conversationContentStyle }}>
                    <div
                      style={
                        {
                          width: '100%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }
                      }
                    >
                      {data.name}
                    </div>
                    <div style={
                      {
                        fontSize: 13, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                        marginTop: 5
                      }
                    }
                    >
                      {data.last_message}
                    </div>
                    <div
                      style={{ position: 'absolute', left: 2, top: 2 }}>
                      {data.unread_count_pdf_cv > 0 ? <MaterialIcon.PictureAsPdf
                        style={{ fontSize: 14, color: 'red' }}/> : ''}
                    </div>
                  </Conversation.Content>
                  {isSidebarOpened ? null : <Conversation.Operations>
                    <Tooltip title={__('delete')}>
                      <IconButton onClick={() => {
                        setOpenConfirmDelete(true)
                        setDeletionConversation(data)
                      }}>
                        <MaterialIcon.Delete/>
                      </IconButton>
                    </Tooltip>
                  </Conversation.Operations>}
                </Conversation>
              </SwipeableListItem>
            )
          })
        }
      </SwipeableList>
      <UserSettingDialog open={openUserSetting} onClose={() => setOpenUserSetting(false)}/>
      <DeleteConversationConfirmDialog
        open={openConfirmDelete} onClose={() => setOpenConfirmDelete(false)}
        onAccepted={deleteConversation}
      />
    </Sidebar>
  )
}

export default ChatBoxSideBar
