import { createSlice } from '@reduxjs/toolkit'

export const defaultSlice = createSlice({
  name: 'message_input',
  initialState: {
    message: ''
  },
  reducers: {
    changeMessageInputText: (state, action) => {
      state.message = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  changeMessageInputText
} = defaultSlice.actions

export default defaultSlice.reducer
