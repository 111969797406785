import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FilledInput,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  Stack
} from '@mui/material'
import React, { useContext, useState } from 'react'
import __ from '../../locales/locale'
import { Controller, useForm } from 'react-hook-form'
import { CurrencyYen } from '@mui/icons-material'
import { useConfirm } from 'material-ui-confirm'
import { RESPONSE_STATUS_ERROR, RESPONSE_STATUS_SUCCESS, USER_TYPE_HCW, USER_TYPE_MED } from '../../constants'
import axios from 'axios'
import OverlayLoading from '../../common_components/OverlayLoading'
import { FirebaseContext } from '../../contexts/firebase'
import { useSelector } from 'react-redux'

function TopUpDialog (props) {
  const firebase = useContext(FirebaseContext)
  const auth = useSelector((state) => state.auth)
  const currentConversation = useSelector(state => state.chatbox.currentConversation)
  const [isOverlayLoading, setOverlayLoading] = useState(false)
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      amount: 10000,
    },
  })
  const confirm = useConfirm()

  const handleTopUp = async data => {
    setOverlayLoading(true)
    try {
      const postFields = data
      if (currentConversation.receiver.type === USER_TYPE_HCW && currentConversation.sender.type === USER_TYPE_MED) {
        postFields['employee_id'] = currentConversation.receiver.external_id
      }
      const result = await axios.post('/payment/topUpPoint', postFields)
      setOverlayLoading(false)
      const res = result.data
      if (result.data.status === RESPONSE_STATUS_SUCCESS) {
        props.onClose()
        await confirm({
          title: '',
          description: res?.message,
          confirmationText: __('unmask')
        }).then(props.onOpenUnmask)
      }
      if (result.data.status === RESPONSE_STATUS_ERROR) {
        props.onClose()
        await confirm({
          title: '',
          description: res?.message,
          hideCancelButton: true,
          confirmationText: __('ok')
        })
      }
      formEvent('form_submit', { form_submit_text: __('submit') })
    } catch (err) {
      //
    }
    setOverlayLoading(false)
  }
  function formEvent (type, data) {
    data = {
      form_id: `${auth.user_id}_evaluate_${currentConversation.receiver_id}`,
      form_name: `Evaluate ${currentConversation.receiver.full_name}`,
      ...data
    }
    if (type === 'form_start') {
      firebase.analyticsFormStartEvent(data)
    }
    if (type === 'form_submit') {
      firebase.analyticsFormSubmitEvent(data)
    }
  }
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <form onSubmit={handleSubmit(handleTopUp)}>
        <DialogTitle>{__('deposit_payment')}</DialogTitle>
        <Divider/>
        <DialogContent>
          <Controller
            name="amount"
            control={control}
            required={true}
            min={10000}
            rules={{ required: __('required_field'), min: __('validate_deposit_minimum') }}
            render={({ field }) => {
              return <FormControl
                error={!!errors?.amount?.message}
                color={errors?.amount?.message ? 'error' : ''}
                fullWidth sx={{ m: '5px 0' }}
                variant="filled"
              >
                <InputLabel htmlFor="filled-adornment-amount">{__('deposit_amount')}</InputLabel>
                <FilledInput
                  {...field}
                  placeholder={`Minimum: 10.000${__('yen')}`}
                  type={'number'}
                  startAdornment={
                    <InputAdornment position="start">
                      <CurrencyYen/>
                    </InputAdornment>
                  }
                />
                <FormHelperText>{errors.amount?.message}</FormHelperText>
              </FormControl>
            }}
          />
          <Stack spacing={1} sx={{ mt: 2 }}>
            <div>※ デポジット残高は0円です。</div>
            <div>※ 情報開示料は1件税込11,000円となります。</div>
          </Stack>
        </DialogContent>
        <Divider/>
        <DialogActions direction={'horizontal'} spacing={1} justifyContent={'flex-end'}>
          <Button type={'submit'} variant={'contained'}>{__('make_deposit')}</Button>
          <Button onClick={props.onClose}>{__('cancel')}</Button>
        </DialogActions>
      </form>
      <OverlayLoading open={isOverlayLoading}/>
    </Dialog>
  )
}

export default TopUpDialog
