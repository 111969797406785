import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import axios from 'axios'
import {
  MESSAGE_TYPE_PDF_CAREER,
  MESSAGE_TYPE_PDF_RESUME,
  PDF_CV_STATUS_EXPIRED,
  PDF_CV_STATUS_LIVING,
  PDF_CV_STATUS_UPDATED,
  RESPONSE_STATUS_SUCCESS,
  USER_TYPE_HCW
} from '../../constants.js'
import __ from '../../locales/locale.js'
import * as MaterialIcon from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import { useSelector } from 'react-redux'

function MessagePdfCvItem (props) {
  const auth = useSelector((state) => state.auth)
  const currentConversation = useSelector((state) => state.chatbox.currentConversation)

  const { message } = props

  const [openPdfCvAlert, setOpenPdfCvAlert] = useState(false)
  const [messagePdfCvAlert, setMessagePdfCvAlert] = useState()

  const handlePdfCvCheckStatus = async () => {
    const result = await axios.get('/pdf/status', { params: { uuid: message.pdf_uuid } })
    const res = result.data
    if (res.status === RESPONSE_STATUS_SUCCESS) {
      switch (res.data.status) {
        case PDF_CV_STATUS_LIVING: {
          window.open(message?.meta_resources?.url + '?access_token=' + auth.access_token)
          break
        }
        case PDF_CV_STATUS_EXPIRED: {
          if (auth.type === USER_TYPE_HCW) {
            setMessagePdfCvAlert(__('pdf_cv_expired_message_for_hcw'))
          } else {
            if (currentConversation.is_unmasked) {
              setMessagePdfCvAlert(__('pdf_cv_expired_message_masked'))
            } else {
              setMessagePdfCvAlert(__('pdf_cv_expired_message_unmasked'))
            }
          }
          setOpenPdfCvAlert(true)
          break
        }
        case PDF_CV_STATUS_UPDATED: {
          if (auth.type === USER_TYPE_HCW) {
            setMessagePdfCvAlert(__('pdf_cv_updated_message_for_hcw'))
          } else {
            if (currentConversation.is_unmasked) {
              setMessagePdfCvAlert(__('pdf_cv_updated_message_masked'))
            } else {
              setMessagePdfCvAlert(__('pdf_cv_updated_message_unmasked'))
            }
          }
          setOpenPdfCvAlert(true)
          break
        }
        default: {}
      }
    }
  }
  return (
    <span>
      <Tooltip title={message.message}>
        <Button
          variant={'outlined'}
          startIcon={<MaterialIcon.PictureAsPdf fontSize={'large'}/>}
          onClick={handlePdfCvCheckStatus}
        >
          {message.type === MESSAGE_TYPE_PDF_CAREER ? __('hcw_career_pdf') : ''}
          {message.type === MESSAGE_TYPE_PDF_RESUME ? __('hcw_resume_pdf') : ''}
        </Button>
      </Tooltip>
      <Dialog open={openPdfCvAlert}>
        <DialogTitle>{__('alert')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{messagePdfCvAlert}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {currentConversation.is_unmasked ? (
            <Button onClick={() => window.open(currentConversation.receiver_url_profile)}>
              {__('details_page_information')}
            </Button>
          ) : ''}
          <Button onClick={() => {setOpenPdfCvAlert(false)}} autoFocus>{__('close')}</Button>
        </DialogActions>
      </Dialog>
    </span>
  )
}

export default MessagePdfCvItem
