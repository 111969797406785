import React from 'react'
import { useSelector } from 'react-redux'
import { MessageSeparator } from '@chatscope/chat-ui-kit-react'
import { Stack } from '@mui/material'

function MessageSystemItem (props) {
  const auth = useSelector((state) => state.auth)
  const currentConversation = useSelector((state) => state.chatbox.currentConversation)
  const { message } = props

  return (
    <Stack mt={2} mb={2}>
      <MessageSeparator>{message.message}</MessageSeparator>
    </Stack>
  )
}

export default MessageSystemItem
