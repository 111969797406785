import { createSlice } from '@reduxjs/toolkit'

const authorization = {
  access_jwt_token: localStorage.getItem('access_jwt_token'),
  access_jwt_token_expired: localStorage.getItem('access_jwt_token_expired'),
  access_token: localStorage.getItem('access_token'),
  refresh_token: localStorage.getItem('refresh_token'),
  user_id: localStorage.getItem('user_id') * 1,
  external_id: localStorage.getItem('external_id') * 1,
  type: localStorage.getItem('type') * 1,
  fcm_token: localStorage.getItem('fcm_token'),
}
export const defaultSlice = createSlice({
  name: 'auth',
  initialState: {
    access_jwt_token: authorization.access_jwt_token,
    access_jwt_token_expired: authorization.access_jwt_token_expired,
    access_token: authorization.access_token,
    refresh_token: authorization.refresh_token,
    user_id: authorization.user_id || 0,
    external_id: authorization.external_id || 0,
    type: authorization.type || 0,
    fcm_token: authorization.fcm_token || '',
  },
  reducers: {
    setAuthorization: (state, action) => {
      state.access_jwt_token = action.payload.access_jwt_token
      state.access_jwt_token_expired = action.payload.access_jwt_token_expired
      state.access_token = action.payload.access_token
      state.refresh_token = action.payload.refresh_token
      state.user_id = Number(action.payload.user_id)
      state.external_id = Number(action.payload.external_id)
      state.type = Number(action.payload.type)
    },
    removeAuthorization: (state, action) => {
      state.access_jwt_token = ''
      state.access_jwt_token_expired = ''
      state.access_token = ''
      state.refresh_token = ''
      state.user_id = ''
      state.external_id = ''
      state.type = ''
    },
    updateAuthorizationJwtToken: (state, action) => {
      state.access_jwt_token = action.payload.access_jwt_token
      state.access_jwt_token_expired = action.payload.access_jwt_token_expired
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  setAuthorization,
  removeAuthorization,
  updateAuthorizationJwtToken,
} = defaultSlice.actions

export default defaultSlice.reducer
