import React from 'react'
import ReactDOM from 'react-dom/client'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import './css/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './redux/store'
import moment from 'moment'
import 'moment/locale/ja'

moment().locale('ja')

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App/>
    </Provider>
  </React.StrictMode>
)
reportWebVitals()
