import { createSlice } from '@reduxjs/toolkit'
import { USER_ONLINE } from '../../constants'

export const defaultSlice = createSlice({
  name: 'chatbox',
  initialState: {
    currentConversation: {
      messages: [],
      id: '',
      name: '',
      avatar: '',
      last_message: '',
      sender_id: 0,
      receiver_id: 0,
      receiver_evaluation_average: 0,
      is_online: false,
      is_unmasked: false,
      status: 0,
      info_permission_status: 0,
      sender: {},
      receiver: {},
      receiver_last_time_at: 0,
      receiver_last_time_info: '',
      receiver_url_profile: '',
    },
    conversations: [],
    isSidebarOpened: false
  },
  reducers: {
    appendMessageForConversation: (state, action) => {
      state.currentConversation.messages.push(action.payload)
    },
    prependMessageForConversation: (state, action) => {
      state.currentConversation.messages = action.payload.concat(state.currentConversation.messages)
    },
    changeCurrentConversation: (state, action) => {
      state.currentConversation.messages = []
      state.currentConversation.id = action.payload.id
      state.currentConversation.name = action.payload.name
      state.currentConversation.avatar = action.payload.avatar
      state.currentConversation.last_message = action.payload.last_message
      state.currentConversation.sender_id = action.payload.sender_id
      state.currentConversation.sender = action.payload.sender
      state.currentConversation.receiver_id = action.payload.receiver_id
      state.currentConversation.receiver = action.payload.receiver
      state.currentConversation.is_online = action.payload.is_online
      state.currentConversation.is_unmasked = action.payload.is_unmasked
      state.currentConversation.status = action.payload.status
      state.currentConversation.info_permission_status = action.payload.info_permission_status
      state.currentConversation.receiver_last_time_at = action.payload.receiver_last_time_at
      state.currentConversation.receiver_evaluation_average = action.payload.receiver_evaluation_average
      state.currentConversation.receiver_url_profile = action.payload.receiver_url_profile
    },
    clearSelectConversation: (state, action) => {
      state.currentConversation = {
        messages: [],
        id: '',
        name: '',
        avatar: '',
        last_message: '',
        sender_id: 0,
        receiver_id: 0,
        receiver_evaluation_average: 0,
        is_online: false,
        is_unmasked: false,
        receiver_last_time_at: 0,
        receiver_last_time_info: '',
        receiver_url_profile: '',
      }
    },
    activeCurrentConversation: (state) => {
      state.conversations = state.conversations.map((row) => {
        row.active = state.currentConversation.id === row.id
        if (state.currentConversation.id === row.id) {
          row.unread_count = 0
        }
        return row
      })
    },
    updateConversation: (state, action) => {
      let flagNew = true
      state.conversations.forEach((row, idx) => {
        if (row.id === action.payload.id) {
          action.payload.is_online = state.conversations[idx].is_online
          state.conversations[idx] = { ...action.payload, active: row.active }
          flagNew = false
        }
      })
      if (flagNew) {
        state.conversations.unshift({ ...action.payload })
      }
      state.conversations = state.conversations.sort((a, b) => {
        return b.timestamp_position - a.timestamp_position
      })
    },
    removeConversation: (state, action) => {
      state.conversations = state.conversations.filter((row) => row.id !== action.payload)
    },
    loadMoreConversation: (state, action) => {
      action.payload = (action.payload || []).map((row) => {
        row.is_online = row.is_online === USER_ONLINE
        return row
      })
      const appendConversations = action.payload.filter((row) => {
        return !state.conversations.find((a) => a.id === row.id)
      })
      state.conversations = appendConversations.concat(state.conversations)
    },
    findConversation: (state, action) => {
      state.conversations.forEach((row, idx) => {
        state.conversations[idx].hidden = row.name.toLowerCase().search(action.payload.toLowerCase()) < 0
      })
    },
    openSidebar: (state) => {
      state.isSidebarOpened = true
    },
    closeSidebar: (state) => {
      state.isSidebarOpened = false
    },
    setCurrentOnline: (state, action) => {
      state.currentConversation.is_online = action.payload.is_online
    },
    setConversationUserStatus: (state, action) => {
      const { user_id, is_online } = action.payload

      if (user_id === state.currentConversation.receiver_id) {
        state.currentConversation.is_online = is_online
      }

      state.conversations = state.conversations.map((row) => {
        if (row.receiver_id === user_id) {
          row.is_online = is_online
        }
        return row
      })
    },
    updateMessageStatus: (state, action) => {
      const found = state.currentConversation.messages.find(a => a.id === action.payload.id)
      found.status = action.payload.status
      found.status_error_message = action.payload.status_error_message
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  appendMessageForConversation,
  prependMessageForConversation,
  changeCurrentConversation,
  loadMoreConversation,
  activeCurrentConversation,
  updateConversation,
  findConversation,
  openSidebar,
  closeSidebar,
  setCurrentOnline,
  setConversationUserStatus,
  updateMessageStatus,
  removeConversation,
  clearSelectConversation
} = defaultSlice.actions

export default defaultSlice.reducer
