import { createTheme } from '@mui/material'

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        'root': {
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23) !important',
              borderWidth: '1px !important'
            }
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23) !important',
            borderWidth: '1px !important'
          }
        }
      }
    }
  }
})

export default theme
