import { Button, Card, CardActions, CardContent, Stack } from '@mui/material'
import React, { useState } from 'react'
import axios from 'axios'
import { RESPONSE_STATUS_SUCCESS, USER_TYPE_MED } from '../../constants.js'
import __ from '../../locales/locale.js'
import { useSelector } from 'react-redux'
import OverlayLoading from '../../common_components/OverlayLoading'
import { useConfirm } from 'material-ui-confirm'
import UserPaymentDialog from '../UserPaymentDialog'
import TopUpDialog from '../payments/TopUpPointDialog'

function MessagePaymentInformationItem (props) {
  const auth = useSelector((state) => state.auth)
  const currentConversation = useSelector((state) => state.chatbox.currentConversation)
  const confirm = useConfirm()
  const { message } = props
  const [isOverlayLoading, setOverlayLoading] = useState(false)
  const [isOpenPaymentDialog, setOpenPaymentDialog] = useState(false)
  const [isOpenTopUpDialog, setOpenTopUpDialog] = useState(false)
  const [pointPaymentData, setPointPaymentData] = useState({
    amount: 0,
    employer_point: 0,
    tax: 0,
    employer_point_will_remain: 0,
  })

  let employerId = 0
  if (currentConversation?.receiver?.type === USER_TYPE_MED) {
    employerId = currentConversation.receiver.external_id
  }

  const openPaymentDialog = async () => {
    setOverlayLoading(true)
    let res
    try {
      const result = await axios.get('/payment/unmask/point/info')
      res = result.data
      setOverlayLoading(false)
    } catch (e) {
      await confirm({
        title: '',
        description: __('system_error_has_occurred'),
        hideCancelButton: true,
        confirmationText: __('ok')
      })
    }
    if (res) {
      if (res.status === RESPONSE_STATUS_SUCCESS) {
        if (res.data.amount > res.data.employer_point) {
          setOpenTopUpDialog(true)
        } else {
          setOpenPaymentDialog(true)
        }
        setPointPaymentData(res.data)
      } else {
        await confirm({
          title: '',
          description: res?.message,
          hideCancelButton: true,
          confirmationText: __('ok')
        })
      }
    }
    setOverlayLoading(false)
  }

  return (
    <Stack alignItems={'center'}>
      <Card variant={'outlined'} sx={{ maxWidth: 350 }}>
        <CardContent>{message.message}</CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button onClick={openPaymentDialog} variant={'contained'} color={'primary'}>
            {__('pay')}
          </Button>
        </CardActions>
      </Card>
      <OverlayLoading open={isOverlayLoading}/>
      <UserPaymentDialog
        open={isOpenPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
        data={pointPaymentData}
      />
      <TopUpDialog
        open={isOpenTopUpDialog}
        onClose={() => setOpenTopUpDialog(false)}
        onOpenUnmask={openPaymentDialog}
      />
    </Stack>
  )
}

export default MessagePaymentInformationItem
