import * as firebase from 'firebase/app'
import { getMessaging } from 'firebase/messaging'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'rehalance.firebaseapp.com',
  projectId: 'rehalance',
  storageBucket: 'rehalance.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const app = firebase.initializeApp(firebaseConfig)

export const messaging = getMessaging(app)
export const analytics = getAnalytics(app)
export default firebase
