import React, { useState } from 'react'
import { Message } from '@chatscope/chat-ui-kit-react'
import {
  MESSAGE_IS_ERROR,
  MESSAGE_IS_SENDING,
  MESSAGE_TYPE_INFO_PERMISSION,
  MESSAGE_TYPE_PAY_INFO,
  MESSAGE_TYPE_PDF_CAREER,
  MESSAGE_TYPE_PDF_RESUME,
  MESSAGE_TYPE_SCOUT,
  MESSAGE_TYPE_SYSTEM,
  PDF_CV_STATUS_EXPIRED,
  PDF_CV_STATUS_LIVING,
  PDF_CV_STATUS_UPDATED,
  RESPONSE_STATUS_SUCCESS,
  USER_TYPE_HCW
} from '../constants'
import { faCircleExclamation, faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as moment from 'moment'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Linkify from 'react-linkify'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useSelector } from 'react-redux'
import __ from '../locales/locale.js'
import axios from 'axios'
import MessageJobItem from './messages/MessageJobItem.js'
import MessagePdfCvItem from './messages/MessagePdfCvItem.js'
import MessageRequestPermissionItem from './messages/MessageRequestPermissionItem'
import MessageSystemItem from './messages/MessageSystemItem'
import MessagePaymentInformationItem from './messages/MessagePaymentInformationItem'

function ChatBoxMessageListItem (props) {
  const auth = useSelector((state) => state.auth)
  const currentConversation = useSelector((state) => state.chatbox.currentConversation)
  const [openPdfCvAlert, setOpenPdfCvAlert] = useState(false)
  const [messagePdfCvAlert, setMessagePdfCvAlert] = useState()
  const { message, position } = props

  const handlePdfCvCheckStatus = async () => {
    const result = await axios.get('/pdf/status', { params: { uuid: message.pdf_uuid } })
    const res = result.data
    if (res.status === RESPONSE_STATUS_SUCCESS) {
      switch (res.data.status) {
        case PDF_CV_STATUS_LIVING: {
          window.open(message?.meta_resources?.url + '?access_token=' + auth.access_token)
          break
        }
        case PDF_CV_STATUS_EXPIRED: {
          if (auth.type === USER_TYPE_HCW) {
            setMessagePdfCvAlert(__('pdf_cv_expired_message_for_hcw'))
          } else {
            if (currentConversation.is_unmasked) {
              setMessagePdfCvAlert(__('pdf_cv_expired_message_masked'))
            } else {
              setMessagePdfCvAlert(__('pdf_cv_expired_message_unmasked'))
            }
          }
          setOpenPdfCvAlert(true)
          break
        }
        case PDF_CV_STATUS_UPDATED: {
          if (auth.type === USER_TYPE_HCW) {
            setMessagePdfCvAlert(__('pdf_cv_updated_message_for_hcw'))
          } else {
            if (currentConversation.is_unmasked) {
              setMessagePdfCvAlert(__('pdf_cv_updated_message_masked'))
            } else {
              setMessagePdfCvAlert(__('pdf_cv_updated_message_unmasked'))
            }
          }
          setOpenPdfCvAlert(true)
          break
        }
        default: {}
      }
    }
  }

  const getMessageContent = (message) => {
    switch (message.type) {
      case MESSAGE_TYPE_PDF_CAREER:
      case MESSAGE_TYPE_PDF_RESUME: {
        return <MessagePdfCvItem message={message}/>
      }
      case MESSAGE_TYPE_SCOUT: {
        return <MessageJobItem message={message}/>
      }
      case MESSAGE_TYPE_INFO_PERMISSION: {
        return <MessageRequestPermissionItem message={message}/>
      }
      default: {}
    }
    return (
      <Linkify componentDecorator={(decoratedHref, decoratedText, key) =>
        <a target="blank" rel="noopener" href={decoratedHref} key={key}>
          {decoratedText}
        </a>}>
        {message.message}
      </Linkify>
    )
  }

  if (message.type === MESSAGE_TYPE_SYSTEM) {
    return <MessageSystemItem message={message}/>
  }

  if (message.type === MESSAGE_TYPE_PAY_INFO) {
    return <MessagePaymentInformationItem message={message}/>
  }

  return <Message
    key={message.id}
    model={{
      message: message?.message?.toString(),
      type: 'text',
      direction: message.direction,
      data: message,
      position: position
    }}
    className={message.type === MESSAGE_TYPE_SCOUT ? 'message-content__scout' : ''}
  >
    <Message.CustomContent>
      <div>
        {getMessageContent(message)}
        <div style={styles.messageStatus} className="message-content__sending-status">
          {message.status === MESSAGE_IS_SENDING ?
            <FontAwesomeIcon color={'orange'} icon={faEllipsis}/> : ''}
          {
            message.status === MESSAGE_IS_ERROR
              ?
              <Tooltip title={message.status_error_message || ''} placement="top-start" arrow>
                <IconButton sx={{ fontSize: 10, padding: 0 }}>
                  <FontAwesomeIcon color={'red'} icon={faCircleExclamation}/>
                </IconButton>
              </Tooltip>
              : ''}
          {/*{message.status === MESSAGE_IS_SENT || !message.status ? <FontAwesomeIcon color={'green'} icon={faCheck}/> : ''}*/}
        </div>
      </div>
      <Dialog open={openPdfCvAlert}>
        <DialogTitle>{__('alert')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{messagePdfCvAlert}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {currentConversation.is_unmasked ? (
            <Button onClick={() => window.open(currentConversation.receiver_url_profile)}>
              {__('details_page_information')}
            </Button>
          ) : ''}
          <Button onClick={() => {setOpenPdfCvAlert(false)}} autoFocus>{__('close')}</Button>
        </DialogActions>
      </Dialog>
    </Message.CustomContent>
    <Message.Footer
      sentTime={
        moment.unix(message.created_at).format('YYYYMMDD') === moment().format('YYYYMMDD')
          ? moment.unix(message.created_at).fromNow()
          : moment.unix(message.created_at).format('HH:mm:ss')
      }/>
  </Message>
}

const styles = {
  messageStatus: {
    fontSize: 10,
    marginTop: 5,
    textAlign: 'right'
  }
}
export default ChatBoxMessageListItem
